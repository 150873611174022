import { useAnimations, useGLTF } from '@react-three/drei';
import { useEffect, useState } from 'react';

export default function Model() {
    const model = useGLTF('./Fox.gltf');
    const animations = useAnimations(model.animations, model.scene);
    console.log(animations);

    // Place the name of the animations
    const animationName = ["Survey", "Run", "Walk"];

    // State to keep track of the current index
    const [currentIndex, setCurrentIndex] = useState(0);

    // Event Handler
    const eventHandler = () => {
        console.log(currentIndex);
        // Increment the index
        setCurrentIndex((prevIndex) => (prevIndex + 1) % animationName.length);
    };

    useEffect(() => {
        const action = animations.actions[animationName[currentIndex]];
        action
            .reset()
            .fadeIn(0.5)
            .play();

            return () =>
                {
                    action.fadeOut(0.5)
                }
            
    }, [currentIndex]);

    return (
        <primitive object={model.scene} scale={0.02} onClick={eventHandler} />
    );
}
